// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from 'framework/src/Message'

export interface Props {
  navigation: any
  id: string;
  t?:any
}

interface S {
  emailSignUpAddress: {email:string,userName:string}
  showAlert?: boolean
  popupData: {
    text: string
    type: 'success' | 'error'
  }
}

interface SS {
  id: any
}

export default class SignUpActivationController extends BlockComponent<
  Props,
  S,
  SS
> {
  resendEmailId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      emailSignUpAddress: {email:'',userName:''},
      showAlert: false,
      popupData: {
        text: '',
        type: 'success',
      },
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    localStorage.setItem('signUpData', '')
    this.setState({
      emailSignUpAddress: JSON.parse(localStorage.getItem('emailSignUpAddress') || ''),
    })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    console.log('responseJsonresponseJson', responseJson)

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.resendEmailId) {
        if (responseJson?.errors) {
          this.setState({
            showAlert: true,
            popupData: {
              text: responseJson?.errors?.[0]?.account,
              type: 'error',
            },
          })
          return
        }
        this.setState({
          showAlert: true,
          popupData: {
            text: this.props.t('Activate email is sent successfully!'),
            type: 'success',
          },
        })
      }
    }
  }

  resendEmailActivation = () => {    
    this.resendEmailId = sendAPIRequest(`account_block/accounts/resend_email_activation?email=${this.state.emailSignUpAddress.email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}
// Customizable Area End
