import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  imgAward,
  imgGlobal,
  imgNotepad,
  imgSupport,
  imgEditAccount,
  imgPassword,
  imgLogout,
  loader,
  loaderBlue
} from "./assets";
import NavigateLayout from "../../../components/src/NavigateLayout";
// Customizable Area End

import Settings2Controller, { Props, configJSON } from "./Settings2Controller";
import { withTranslation } from "react-i18next";
class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  accountMenu = [
    {
      title: this.props.t("Edit account"),
      icon: imgEditAccount,
      navigation: "EditAccount",
    },
    {
      title: this.props.t("Password"),
      icon: imgPassword,
      navigation: "ChangePassword",
    },
  ];

  generalMenu = [
    {
      title: this.props.t("Language"),
      icon: imgGlobal,
      navigation: "LanguageOptions",
    },
    {
      title: this.props.t("Subscription"),
      icon: imgAward,
      navigation: "StripePaymentsView",
    },
    {
      title: this.props.t("Contact Us"),
      icon: imgSupport,
      navigation: "Contactus",
    },
    {
      title: this.props.t("Terms and Conditions"),
      icon: imgNotepad,
      navigation: "TermsConditions",
    },
  ];

  handleLogout = () => {
    this.props.navigation.navigate('EmailLogin')
    localStorage.clear()
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Settings">
        <SettingContainer>
        {this.state.isLoading ? (
        <Box className="loaderShown">
        <img src={loaderBlue} alt="Loading" />
      </Box>
    ) : 
          <>
          <Box className="settingBackground">
            <Box className="settingImgWrapper">
              <div />
              <Typography className="settingTitle">{this.props.t("Settings")}</Typography>
              <img src={this.state.teddySetting} className="teddyImage" alt="Teddy Setting" />
              <img src={this.state.teddySetting} className="teddyImageMobile" alt="Teddy Setting" />
            </Box>
          </Box>
          <Box className="contentContainer">
            <Box className="listWrapper">
              <List
                component="nav"
                className="accountWrapper"
                subheader={
                  <ListSubheader disableSticky component="div" className="settingListTitle">
                    {this.props.t("Account")}
                  </ListSubheader>
                }
              >
                {this.accountMenu.map((item, index) => (
                  <ListItem
                    key={item.title}
                    button
                    id='testAccountItem'
                    className={
                      index === this.accountMenu.length - 1 ? 'paddingLeft20' : 'paddingLeft20 borderBottom'
                    }
                    onClick={() => {
                      this.onNavigate(item.navigation)
                    }
                    }
                  >
                    <ListItemIcon>
                      <img src={item.icon} width={18} height="auto" />
                    </ListItemIcon>
                    <ListItemText primary={item.title} className="listItemText" />
                  </ListItem>
                ))}
              </List>
              <List
                component="nav"
                className="accountWrapper marginTop30"
                subheader={
                  <ListSubheader disableSticky component="div" className="settingListTitle">
                    {this.props.t("General")}
                  </ListSubheader>
                }
              >
                {this.generalMenu.map((item, index) => (
                  <ListItem
                    key={item.title}
                    button
                    id='testLisGeneralItem'
                    className={
                      index === this.generalMenu.length - 1 ? 'paddingLeft20' : 'paddingLeft20 borderBottom'
                    }
                    onClick={() => this.onNavigate(item.navigation)}
                  >
                    <ListItemIcon>
                      <img src={item.icon} width={18} height="auto" />
                    </ListItemIcon>
                    <ListItemText primary={item.title} className="listItemText" />
                    {index === 1 && this.state.loading ? <img src={loader} width={25} alt="Loading" /> : <></>}
                  </ListItem>
                ))}
              </List>
              <Button
                onClick={this.handleLogout}
                variant="outlined"
                className="logoutBtn"
                startIcon={<img src={imgLogout} width={18} height="auto" />}
              >
                {this.props.t("Log out")}
              </Button>
            </Box>
          </Box>
          </>
         }
        </SettingContainer>
      </NavigateLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(Settings2)
const SettingContainer = styled(Box)(({theme}) => ({
  backgroundColor: '#A95EDB',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .settingBackground': {
    backgroundColor: '#fff',
  },
  '& .loaderShown': {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    width: "calc(100% - 240px)",
     marginLeft: "100px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },

  '& .settingImgWrapper': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#A95EDB',
    borderBottomRightRadius: '20px',
    paddingTop: '43px',
  },

  '& .settingTitle': {
    fontSize: '22px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#fff',
    marginBottom: '-32px'
  },

  '& .teddyImage': {
    display: 'none'
  },

  '& .teddyImageMobile': {
    display: 'block',
    width: '240px',
    height: 'auto',
    marginTop:'30px'
  },

  '& .contentContainer': {
    flex: '6',
    backgroundColor: '#fff',
    overflow: 'scroll',
    padding: '32px 0 100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    scrollbarWidth: 'none'
  },

  '& .contentContainer::-webkit-scrollbar': {
    display: 'none'
  },

  '& .listWrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 24px'
  },

  '& .accountWrapper': {
    width: '100%',
    maxWidth: '450px',
    border: '2px solid #e6e6e6',
    borderRadius: '12px',

    '& .MuiListItem-root': {
      paddingTop: '4px',
      paddingBottom: '4px'
    }
  },

  '& .settingListTitle': {
    height: '39px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#999999',
    fontSize: '12px',
    textTransform:'uppercase',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    backgroundColor: '#e6e6e6',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },

  '& .listItemText': {
    '& span': {
      fontSize: '18px',
      fontFamily: 'Quicksand',
      fontWeight: '500',
    }
  },

  '& .paddingLeft20': {
    paddingLeft: '20px',
  },

  '& .borderBottom': {
    borderBottom: '1px solid #e6e6e6',
  },

  '& .marginTop30': {
    marginTop: '30px',
  },

  '& .logoutBtn': {
    justifyContent: 'flex-start',
    marginTop: '30px',
    padding: '8px 20px',
    width: '100%',
    maxWidth: '450px',
    borderRadius: '10px',
    border: '2px solid #e6e6e6',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: 'Quicksand',
    fontWeight: '500',

    '& .MuiButton-startIcon': {
      marginLeft: '0px',
      marginRight: '38px',
    }
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .contentContainer': {
      paddingTop: '90px',
    },

    '& .settingBackground': {
      flex: 5,
    },

    '& .settingImgWrapper': {
      justifyContent: 'space-between',
      paddingTop: 'unset',
    },

    '& .teddyImage': {
      display: 'block',
      width: "360px",
      height: "auto",
    },

    '& .teddyImageMobile': {
      display: 'none',
    },

    '& .settingTitle': {
      fontSize: '30px',
      marginBottom: 'unset',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  }
}))

// Customizable Area End
