import React from "react";

// Customizable Area Start
import ContactusController, { Props } from "./ContactusController";
import NavigateLayout from "../../../components/src/NavigateLayout";
import { Box, Typography, styled, Modal } from "@material-ui/core";
import SettingBackground from "../../settings2/src/SettingBackground";
import SettingHeader from "../../settings2/src/SettingHeader";
import {
  imgButtonClose,
  imgContactInstagram,
  imgContactPhone,
  imgContactSms,
  imgContactTiktok,
  imgContactTwitter,
  imgContactUsHeader,
  imgGroupContactUsIcon,
} from "./assets";
import { ButtonCustom, TextInputCustom } from "../../../components/src/MaterialTheme";
import * as Yup from 'yup'
import { Formik } from 'formik'
import { withTranslation } from "react-i18next";
// Customizable Area End

class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  handleClickGoBack = () => {
    this.props.navigation.goBack()
  }
  contactUsSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email(this.props.t('Email invalid'))
      .required(this.props.t('Email is required')),
    name: Yup.string().trim().required(this.props.t('Name is required')),
    description: Yup.string()
      .trim()
      .required(this.props.t('Message is required'))
  })

  handleCloseModal = () => this.setState({showModalSuccess: false})

  renderErrorInfo = (isShow: boolean, errorText: string | undefined) => {
    const text = errorText || this.props.t('Please complete the required fields.')
    return isShow && (
      <div className="errorAlert">
        {text}
      </div>
    )
  }
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Settings">
        <ContactUsContainer>
          <SettingBackground />
          <Box className="contentContainer">
            <SettingHeader
              onGoBack={this.handleClickGoBack}
              title={this.props.t("Contact us")}
              headerImage={imgContactUsHeader}
              isShowButton={false}
            />
            <Box className="contactUsContainer">
              <Box className="contactUsWrapper">
                <Box className="introWrapper" >
                  <img src={imgGroupContactUsIcon} className="introImage" />
                  <Box>
                    <Typography className="introTitle">
                      {this.props.t("Get in touch!")}
                    </Typography>
                    <Typography className="introText" dangerouslySetInnerHTML={{ __html: this.props.t("We're here to help you with <span>anything</span> you need.")}} />
                  </Box>
                </Box>
                <Formik
                  data-testId='changeForm'
                  key={JSON.stringify(this.state.isLoading)}
                  enableReinitialize={true}
                  initialValues={this.state.contactData}
                  validationSchema={this.contactUsSchema}
                  onSubmit={
                    this.submitContactUs
                  }
                >
                  {({ errors, touched, handleSubmit, handleChange, values }) => (
                    <form
                      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                      onSubmit={handleSubmit}
                    >
                     {this.renderErrorInfo(
                      this.checkTouchedAndErrorInput(touched.name, errors.name)||
                      this.checkTouchedAndErrorInput(touched.email, errors.email)||
                      this.checkTouchedAndErrorInput(touched.description, errors.description) ,
                     this.getErrors(errors))}
                    { console.log("######", this.state.contactData, values)}
                      <TextInputCustom
                        variant="outlined"
                        placeholder={this.props.t("Your name")}
                        value={values.name}
                        onChange={handleChange}
                        error={Boolean(touched.name && errors.name)}
                        autoComplete="off"
                        name="name"
                      />
                      <TextInputCustom
                        variant="outlined"
                        placeholder={this.props.t("Your email")}
                        value={values.email}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                        autoComplete="off"
                        name="email"
                        style={{marginBottom: '15px', marginTop: '15px'}}
                      />
                      <TextInputCustom
                        variant="outlined"
                        multiline
                        minRows={6}
                        placeholder={this.props.t("Your message")}
                        value={values.description}
                        onChange={handleChange}
                        error={Boolean(touched.description && errors.description)}
                        autoComplete="off"
                        name="description"
                        style={{marginBottom: '30px'}}
                      />
                      <ButtonCustom data-testId='submitContact' type="submit" disabled={this.state.isLoading}>{this.props.t("Send message")}</ButtonCustom>
                    </form>
                  )}
                </Formik>
                <Box className="infoContainer">
                  <Typography className="infoTitle">{this.props.t("Stay connected!")}</Typography>
                  <Box className="infoContent">
                    <Box className="infoContentWrapper">
                      <Box style={{display: 'flex', marginBottom: '8px'}}>
                        <img src={imgContactSms} width={18} height='auto' />
                        <Typography className="infoContentText">contact@tedix.eu</Typography>
                      </Box>
                      <Box style={{display: 'flex', marginBottom: '16px'}}>
                        <img src={imgContactPhone} width={18} height='auto' />
                        <Typography className="infoContentText">+40 (729) 308 - 668</Typography>
                      </Box>
                      <Box style={{display: 'flex', gap: '24px'}}>
                        <a 
                          href="https://www.instagram.com/tedix.eu/" 
                          target="_blank" 
                          rel="noopener noreferrer" 
                        >
                          <Box className="infoSocialWrapper">
                            <img src={imgContactInstagram} width={20} height={20} />
                          </Box>
                        </a>
                        <a 
                          href="https://x.com/tedix_eu" 
                          target="_blank" 
                          rel="noopener noreferrer" 
                        >
                          <Box className="infoSocialWrapper">
                            <img src={imgContactTwitter} width={20} height={20} />
                          </Box>
                        </a>
                        <a 
                          href="https://www.tiktok.com/@tedix.eu" 
                          target="_blank" 
                          rel="noopener noreferrer" 
                        >
                          <Box className="infoSocialWrapper">
                            <img src={imgContactTiktok} width={20} height={20} />
                          </Box>
                        </a>
                      </Box>
                    </Box>
                    <img src={this.state.contactUsAvatar} width={114} height='auto' />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <ModalStyled
            open={this.state.showModalSuccess}
            onClose={this.handleCloseModal}
          >
            <Box className="container">
              <div className="closeBtn" onClick={this.handleCloseModal}>
                <img src={imgButtonClose} width={10} height={10} />
              </div>
              <img src={this.state.msgTeddy} className="teddyImg" />
              <Typography className="title">{this.props.t("Message on its way!")}</Typography>
              <Typography className="description">
               {this.props.t("Your message is flying to us. We'll respond in a flash!")}
              </Typography>
            </Box>
          </ModalStyled>
        </ContactUsContainer>
      </NavigateLayout>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    )
  }
}

// Customizable Area Start
export default withTranslation()(Contactus)
const ContactUsContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#A95EDB',

  '& .contentContainer': {
    flex: '6',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .contactUsContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'scroll',
    backgroundColor: '#A95EDB',
    scrollbarWidth: 'none'
  },

  '& .contactUsContainer::-webkit-scrollbar': {
    display: 'none'
  },

  '& .contactUsWrapper': {
    flex: 1,
    overflow: 'scroll',
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 0 88px',
    backgroundColor: '#fff',
    scrollbarWidth: 'none'
  },

  '& .contactUsWrapper::-webkit-scrollbar': {
    display: 'none'
  },

  '& .introWrapper': {
    display: 'flex', 
    alignItems: 'center',
    marginBottom: '45px',
    maxWidth: '300px'
  },

  '& .introTitle': {
    fontFamily: 'Quicksand', 
    fontSize: '20px', 
    fontWeight: '700', 
    color: '#363636'
  },

  '& .introText': {
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontWeight: '500',
    color: '#666666',

    '& span': {
      fontWeight: '600',
    }
  },

  '& .introImage': {
    width: '52px',
    height: 'auto',
    marginRight: '20px'
  },

  '& .infoContainer': {
    width: '100%',
    marginTop: '40px',
    paddingTop: '32px',
    paddingBottom: '32px',
    backgroundColor: '#D9F3FF',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  '& .infoTitle': {
    fontFamily: 'Quicksand',
    fontSize: '20px',
    fontWeight: '700',
    color: '#363636',
    marginBottom: '36px'
  },

  '& .infoContent': {
    display: 'flex'
  },

  '& .infoContentWrapper': {
    marginRight: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  '& .infoContentText': {
    marginLeft: '12px',
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: '500',
    color: '#7F7F7F'
  },

  '& .errorAlert': {
    width: '300px',
    backgroundColor: '#FEE2DF',
    color: '#F96855',
    fontSize: '14px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    marginBottom: '16px',
  },

  '& .infoSocialWrapper': {
    width: '40px',
    height: '40px',
    backgroundColor: '#effaff',
    borderRadius: '8px',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .contactUsWrapper': {
      borderRadius: '24px 0 0 0',
    },

    '& .introWrapper': {
      maxWidth: '400px'
    },
    '& .errorAlert': {
      width: '400px',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))

const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 292,
    backgroundColor: '#F7F7F7',
    padding: 26,
    borderRadius: 12,
    border: '2px solid #e6e6e6',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .closeBtn': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },

  '& .teddyImg': {
    width: '150px',
    height: 'auto',
    marginRight: '20px',
    marginBottom: '13px'
  },

  '& .title': {
    fontWeight: '900',
    fontFamily: 'Nunito',
    fontSize: '18px',
    color: '#363636'
  },

  '& .description': {
    fontFamily: 'Quicksand', 
    fontSize: '15px',
    fontWeight: '500',
    color: '#666666',
    textAlign: 'center',
    marginTop: '10px'
  },
  
}))

// Customizable Area End
