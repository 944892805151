// Customizable Area Start

import React from 'react'
import { Container, Typography, Box, styled, Snackbar } from '@material-ui/core'

import { imgSignUpActivate } from '../src/assets'
import SignUpActivationController from './SignUpActivationController'
import SelectLanguage from '../../../components/src/SelectLanguage'
import Header from '../../../components/src/Header'
import { withTranslation } from 'react-i18next'
 class SignUpActivation extends SignUpActivationController {
  handleGoBack = () => {
    this.props.navigation.navigate('EmailLogin')
  }

  handleClose = () => {
    this.setState({ showAlert: false })
  }

  handleResend = () => {
    this.resendEmailActivation()
  }

  render() {
    return (
      <SignUpActivationContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentContainer" maxWidth="md">
          <div />
          <Box className="contentWrapper">
            <Typography className="activateEmailTitle">
            {this.state.emailSignUpAddress.userName}, {this.props.t("you are almost there")}!
            </Typography>
            <Typography className="activateEmailDesc">
              {this.props.t("We just need to verify your email.")}
            </Typography>
            <img alt="Tedix mail activate" src={imgSignUpActivate} className="signUpActivateImg" />
            <Typography className="activateEmailTitle">
              {this.props.t("Check your mail")}
            </Typography>
            <Typography className="activateEmailDesc">
              {this.props.t("We have sent a verification email to")}{' '}
              <span style={{ color: '#363636' }}>
                {this.state.emailSignUpAddress.email}
              </span>
            </Typography>
          </Box>
          <Box className="footerWrapper">
            <Typography className="footerText">
              {this.props.t("Did not receive the email? Check your spam filter, or try")}{' '}
              <span
                data-test-id="sendAgainButton"
                className="sendAgainButton"
                onClick={this.handleResend}
              >
                {this.props.t("sending it again.")}
              </span>
            </Typography>
            <SelectLanguage style={{ marginTop: '36px' }} />
          </Box>
        </Container>
        <Snackbar
          open={this.state.showAlert}
          onClose={this.handleClose}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={2000}
        >
          <Box
            className="snackBarContainer"
            style={{
              backgroundColor:
                this.state.popupData.type === 'error' ? '#F96855' : '#62CDFF',
            }}
          >
            <Typography className="alertText">
              {this.state.popupData.text}
            </Typography>
          </Box>
        </Snackbar>
      </SignUpActivationContainer>
    )
  }
}

export default withTranslation()(SignUpActivation)

const SignUpActivationContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  
  '& .contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
  },

  '& .activateEmailTitle': {
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#363636',
  },

  '& .activateEmailDesc': {
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'Quicksand',
    color: '#666666',
    marginTop: '10px',
    fontWeight: '700',
  },

  '& .signUpActivateImg': {
    width: '125px',
    height: 'auto',
    marginTop: '72px',
    marginBottom: '37px',
  },

  '& .footerWrapper': {
    marginBottom: '72px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .footerText': {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    color: '#999999',
    marginTop: '85px',

    '& span': {
      color: '#36BFFF',
      cursor: 'pointer',
    },
  },

  '& .snackBarContainer': {
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '6px 16px',
    color: '#fff',
  },

  '& .alertText': {
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
}))
